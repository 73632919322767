import {
  bookOutline,
  businessOutline,
  newspaperOutline,
  peopleOutline,
  pieChartOutline,
} from "ionicons/icons";
import Dashboard from "../../pages/dashboard/Dashboard";
import RecordBookPreview from "../../pages/recordBook/RecordBookPreview";
import NewsPreview from "../../pages/news/NewsPreview";
import ProfilePage from "../../pages/profile/Profile";
import TraineePreview from "../../pages/trainees/TraineesPreview";
import CompanyPreview from "../../pages/company/Company";
import RecordBookDetail from "../../pages/recordBook/RecordBookDetail";
import TraineeReportPreview from "../../pages/trainees/TraineeReportPreview";
import Settings from "../../pages/profile/Settings";
import CompanySettings from "../../pages/profile/settings/CompanySettings";
import TrainingSettings from "../../pages/profile/settings/TrainingSettings";
import AccountSettings from "../../pages/profile/settings/AccountSettings";
import PrintRecordBook from "../../pages/printRecordBook/PrintRecordBook";
import TraineeReportDetail from "../../pages/trainees/TraineeReportDetail";
import RecordBookDay from "../../pages/recordBook/RecordBookDay";
import NewsDetail from "../../pages/news/NewsDetail";
import { LegalNoticeAuthenticated } from "../../pages/official/LegalNotice";
import { PrivacyAuthenticated } from "../../pages/official/Privacy";
import { DisclaimerPageAuthenticated } from "../../pages/official/Disclaimer";
import NotificationSettings from "../../pages/profile/settings/NotificationSettings";

interface Route {
  label: string;
  path: string;
  component: any;
  istab: boolean;
  sideMenuDesktop: boolean;
  sideMenuMobile: boolean;
  icon?: string;
  roles: Array<String>;
  protected: boolean;
  legal?: boolean;
}

export const routes: Array<Route> = [
  {
    label: "Dashboard",
    path: "/tabs/dashboard",
    component: Dashboard,
    istab: true,
    sideMenuDesktop: true,
    sideMenuMobile: true,
    icon: pieChartOutline,
    roles: ["trainee", "trainer", "company_admin"],
    protected: true,
  },
  {
    label: "Berichtsheft",
    path: "/tabs/berichtsheft",
    component: RecordBookPreview,
    istab: true,
    sideMenuDesktop: true,
    sideMenuMobile: true,
    icon: bookOutline,
    roles: ["trainee"],
    protected: true,
  },
  {
    label: "Berichtsheft",
    path: "/tabs/berichtsheft/:filter?",
    component: RecordBookPreview,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: false,
    roles: ["trainee"],
    protected: true,
  },
  {
    label: "Berichtsheft",
    path: "/tabs/berichtsheft/:year/:week",
    component: RecordBookDetail,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: false,
    roles: ["trainee"],
    protected: true,
  },
  {
    label: "Berichtsheft",
    path: "/tabs/berichtsheft/:year/:week/:dayId",
    component: RecordBookDay,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: false,
    roles: ["trainee"],
    protected: true,
  },
  {
    label: "Berichtsheft drucken",
    path: "/tabs/berichtsheft-drucken",
    component: PrintRecordBook,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: false,
    roles: ["trainee"],
    protected: true,
  },
  {
    label: "Berichtsheft drucken",
    path: "/tabs/azubis/:traineeId/berichtsheft-drucken",
    component: PrintRecordBook,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: false,
    roles: ["trainer", "company_admin"],
    protected: true,
  },
  {
    label: "Meine Azubis",
    path: "/tabs/azubis",
    component: TraineePreview,
    istab: true,
    sideMenuDesktop: true,
    sideMenuMobile: true,
    icon: peopleOutline,
    roles: ["trainer", "company_admin"],
    protected: true,
  },
  {
    label: "Meine Azubis",
    path: "/tabs/azubis/:traineeId",
    component: TraineeReportPreview,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: false,
    roles: ["trainer", "company_admin"],
    protected: true,
  },
  {
    label: "Meine Azubis",
    path: "/tabs/azubis/:traineeId/:year/:week",
    component: TraineeReportDetail,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: false,
    roles: ["trainer", "company_admin"],
    protected: true,
  },
  {
    label: "Unternehmen",
    path: "/tabs/unternehmen",
    component: CompanyPreview,
    istab: true,
    icon: businessOutline,
    sideMenuDesktop: true,
    sideMenuMobile: true,
    roles: ["company_admin"],
    protected: true,
  },
  {
    label: "News",
    path: "/tabs/news",
    component: NewsPreview,
    istab: true,
    sideMenuDesktop: true,
    sideMenuMobile: true,
    icon: newspaperOutline,
    roles: ["trainee", "trainer", "company_admin"],
    protected: true,
  },
  {
    label: "News",
    path: "/tabs/news/:id",
    component: NewsDetail,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: false,
    roles: ["trainee", "trainer", "company_admin"],
    protected: true,
  },
  {
    label: "Profil",
    path: "/tabs/profil",
    component: ProfilePage,
    istab: false,
    sideMenuDesktop: true,
    sideMenuMobile: true,
    roles: ["trainee", "trainer", "company_admin"],
    protected: true,
  },
  {
    label: "Einstellungen",
    path: "/tabs/profil/system",
    component: Settings,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: false,
    roles: ["trainee", "trainer", "company_admin"],
    protected: true,
  },
  {
    label: "Einstellungen",
    path: "/tabs/profil/company",
    component: CompanySettings,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: false,
    roles: ["trainee", "trainer", "company_admin"],
    protected: true,
  },
  {
    label: "Einstellungen",
    path: "/tabs/profil/ausbildung",
    component: TrainingSettings,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: false,
    roles: ["trainee"],
    protected: true,
  },
  {
    label: "Einstellungen",
    path: "/tabs/profil/benachrichtigungen",
    component: NotificationSettings,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: false,
    roles: ["trainee", "trainer", "company_admin"],
    protected: true,
  },
  {
    label: "Einstellungen",
    path: "/tabs/profil/account",
    component: AccountSettings,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: false,
    roles: ["trainee", "trainer", "company_admin"],
    protected: true,
  },
  {
    label: "Impressum",
    path: "/tabs/impressum",
    component: LegalNoticeAuthenticated,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: true,
    roles: ["trainee", "trainer", "company_admin"],
    protected: true,
    legal: true,
  },
  {
    label: "Datenschutz",
    path: "/tabs/datenschutz",
    component: PrivacyAuthenticated,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: true,
    roles: ["trainee", "trainer", "company_admin"],
    protected: true,
    legal: true,
  },
  {
    label: "Disclaimer",
    path: "/tabs/disclaimer",
    component: DisclaimerPageAuthenticated,
    istab: false,
    sideMenuDesktop: false,
    sideMenuMobile: true,
    roles: ["trainee", "trainer", "company_admin"],
    protected: true,
    legal: true,
  },
];
