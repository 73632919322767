import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonItem, IonLabel, IonList, IonLoading, IonPage, IonRow, IonSelect, IonSelectOption, IonToggle } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { BackButtonRow, PageContainer } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../data/redux/hooks";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { copyRequiredUserData } from "../../../util/profile-helper";
import { editUser } from "../../../data/redux/userSlice";

interface NotificationSettingsProps extends RouteComponentProps<{}> { }

interface BackupFormData {
    backup: string
}

interface MailNotificationFormData {
    mail_notification: boolean
}

const NotificationSettings: React.FC<NotificationSettingsProps> = () => {
    const { user, updating } = useAppSelector((state) => state.user)
    const { control, handleSubmit } = useForm<BackupFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            backup: (user && user.backup) ? user.backup : "never",
        }
    });
    const { control: control_MN, handleSubmit: handleSubmit_MN } = useForm<MailNotificationFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            mail_notification: (user && user.mail_notification && user.mail_notification === 1) ? true : false,
        }
    });
    const dispatch = useAppDispatch();

    const onSubmitBackup: SubmitHandler<BackupFormData> = (data: BackupFormData) => {
        //TODO: ist aktuell ungetesteter Pseudo Code
        let userClone = copyRequiredUserData(user!);
        userClone.backup = data.backup;
        dispatch(editUser(userClone))
    }

    const onChangeMailNotification = (data: MailNotificationFormData) => {
        //TODO: ist aktuell ungetesteter Pseudo Code
        let userClone = copyRequiredUserData(user!);
        userClone.mail_notification = data.mail_notification ? 1 : 0;
        dispatch(editUser(userClone))
    }

    return (
        <IonPage>
            <PageContainer name="Einstellungen" backButton={true}>
                <>
                    <IonGrid>
                        <BackButtonRow path="/tabs/profil" />
                        <IonRow>

                            {/* Automatische PDF Backup */}
                            {user.role === "trainee" && <IonCol size="12">
                                <IonCard>
                                    <IonCardContent>
                                        <IonLabel>
                                            <h3><b>Automatiches PDF-Backup</b></h3>
                                            <p>Hier kannst du einstellen, ob du dein Ausbildungs-Berichtsheft regelmäßig als PDF an deine E-Mail-Adresse erhalten möchtest. Wähle, ob du die PDF wöchentlich oder monatlich bekommen willst. So hast du immer eine gesicherte Kopie und kannst bequem darauf zugreifen.</p>
                                        </IonLabel>

                                        <form onSubmit={handleSubmit(onSubmitBackup)}>
                                            <div style={{ textAlign: "right" }}>
                                                <IonList>
                                                    <IonItem className="registerInput">
                                                        <Controller
                                                            name="backup"
                                                            control={control}
                                                            render={({ field }) => <IonSelect
                                                                labelPlacement="floating"
                                                                label="Häufigkeit"
                                                                value={field.value}
                                                                onIonChange={(e: any) => field.onChange(e.detail.value)}
                                                                onIonBlur={() => field.onBlur()}
                                                                cancelText="Abbrechen"
                                                            >
                                                                <IonSelectOption value="never">Nie</IonSelectOption>
                                                                <IonSelectOption value="weekly">Wöchentlich</IonSelectOption>
                                                                <IonSelectOption value="monthly">Monatlich</IonSelectOption>
                                                            </IonSelect>}
                                                        />

                                                    </IonItem>
                                                </IonList>
                                                <IonButton type="submit">Speichern</IonButton>
                                            </div>
                                        </form>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>}

                            <IonCol size="12">
                                <IonCard>
                                    <IonCardContent>

                                        <IonLabel>
                                            <h3><b>Benachrichtigungen</b></h3>
                                            {user.role === "trainee" ? <p>Hier kannst du festlegen, ob du eine E-Mail-Benachrichtigung erhalten möchtest, wenn dein Ausbilder einen Berichtshefteintrag akzeptiert oder abgelehnt hat.</p> : <p>Hier können Sie festlegen, ob Sie eine E-Mail-Benachrichtigung erhalten möchten, sobald ein Azubi einen neuen Berichtshefteintrag zur Prüfung einreicht.</p>}
                                        </IonLabel>
                                        <form onSubmit={handleSubmit_MN(onChangeMailNotification)}>
                                            <div style={{ textAlign: "right" }}>
                                                <IonList>
                                                    <IonItem className="registerInput">
                                                        <Controller
                                                            name="mail_notification"
                                                            control={control_MN}
                                                            render={({ field }) =>
                                                                <IonToggle checked={field.value} onIonChange={(e) => field.onChange(e.detail.checked)}>E-Mail Benachrichtigungen zulassen</IonToggle>
                                                            }
                                                        />
                                                    </IonItem>
                                                </IonList>
                                                <IonButton type="submit">Speichern</IonButton>
                                            </div>
                                        </form>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>


                        </IonRow>
                    </IonGrid>
                    <IonLoading
                        isOpen={updating}
                        message="Lädt..."
                    />
                </>
            </PageContainer>
        </IonPage>
    )
}

export default NotificationSettings;