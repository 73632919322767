import { User } from "../models/userModels";

export const mapProfileRoleText = (user: User) => {
  switch (user.role) {
    case "trainee": {
      if (user.trainee_year && user.trainee_year !== null) {
        return "(" + user.trainee_year + ". Lehrjahr)";
      }
      return "";
    }
    case "trainer": {
      let text: String = "Ausbilder";
      if (user.company_name !== "") {
        text += " (" + user.company_name + ")";
      } else if (user.custom_company_name !== "") {
        text += " (" + user.custom_company_name + ")";
      }
      return text;
    }
    default:
      return "";
  }
};

export const copyRequiredUserData = (user: User) => {
  let userCopy: User = {
      id: user.id,
      company_id: user.company_id,
      company_name: user.company_name,
      company_token: user.company_token,
      department_id: user.department_id,
      name: user.name,
      job: user.job,
      first_name: user.first_name,
      email: user.email,
      trainee_ident_no: user.trainee_ident_no,
      trainee_start: user.trainee_start,
      trainee_end: user.trainee_end,
      trainee_year: user.trainee_year,
      trainee_year_label: user.trainee_year_label,
      report_start: user.report_start,
      custom_company_name: user.custom_company_name,
      custom_trainer_name: user.custom_trainer_name,
      location_id: user.location_id,
      role: user.role,
      report_type: user.report_type,
      backup: user.backup,
      mail_notification: user.mail_notification
  }
  return userCopy;
}
