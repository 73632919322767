import { IonAvatar, IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonPage, IonRow } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { PageContainer } from "../../components";

import "./Profile.scss";
import { alertCircleOutline, businessOutline, keyOutline, notificationsOutline, schoolOutline } from "ionicons/icons";
import { mapProfileRoleText } from "../../util/profile-helper";
import { useAppSelector } from "../../data/redux/hooks";



const ProfilePage: React.FC<RouteComponentProps> = () => {
    const { user } = useAppSelector((state) => state.user)

    return (
        <IonPage>

            <PageContainer name="Profil" backButton={false}>
                <IonGrid>
                    <IonRow>
                        {/* Profile Card */}
                        <IonCol size="12">
                            <IonCard className="profileCard">
                                <IonCardContent>
                                    <div className="profileCardInner">
                                        <IonAvatar>
                                            <img src={user && user.image ? user.image : "./assets/images/profile_placeholder.jpg"} alt="Profilbild" />
                                        </IonAvatar>
                                        <div className="profileHeader">{user && user.first_name} {user && user.name}</div>
                                        <div className="profileSubheader">{user && mapProfileRoleText(user)}</div>
                                    </div>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>

                        <IonCol size="12">
                            <IonCard>
                                <IonCardContent>
                                    <IonList>
                                        <IonItem routerLink="/tabs/profil/account">
                                            <IonIcon slot="start" icon={keyOutline} />
                                            <IonLabel>
                                                <h3>Mein Konto</h3>
                                                <p>Avatar, Name, Passwort</p>
                                            </IonLabel>
                                        </IonItem>

                                        <IonItem routerLink="/tabs/profil/company">
                                            <IonIcon slot="start" icon={businessOutline} />
                                            <IonLabel>
                                                <h3>Unternehmen</h3>
                                                <p>Zugehörigkeit, Informationen</p>
                                            </IonLabel>
                                        </IonItem>

                                        {(user && user.role === "trainee") && <IonItem routerLink="/tabs/profil/ausbildung">
                                            <IonIcon slot="start" icon={schoolOutline} />
                                            <IonLabel>
                                                <h3>Ausbildung</h3>
                                                <p>Beruf, Zeitraum, Berichtsheft</p>
                                            </IonLabel>
                                        </IonItem>}

                                        <IonItem routerLink="/tabs/profil/benachrichtigungen">
                                            <IonIcon slot="start" icon={notificationsOutline} />
                                            <IonLabel>
                                                <h3>Benachrichtigungen</h3>
                                                <p>Nachrichten, Erinnerungen</p>
                                            </IonLabel>
                                        </IonItem>

                                        <IonItem routerLink="/tabs/profil/system">
                                            <IonIcon slot="start" icon={alertCircleOutline} />
                                            <IonLabel>
                                                <h3>System</h3>
                                                <p>Version, Design</p>
                                            </IonLabel>
                                        </IonItem>
                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </PageContainer>

        </IonPage>
    )
}

export default ProfilePage;