import { HttpOptions } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

/* Lokal */
/* const baseURL = "http://127.0.0.1:8000/api"; */
/* DEV */
const baseURL= "https://api-dev.woodbook.info/api";
/* Woodbook */
/* const baseURL= "https://api.woodbook.info/api"; */

const baseHttpOptions = async (url: string) => {
    const { value } = await Preferences.get({ key: 'token' })

    let options: HttpOptions = {
        url: `${baseURL}/${url}`,
        headers: {
            'Content-Type': 'application/json',
        },
    };


    if (value) {
        options.headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${value}`
        }
    }

    return options;
}

export default baseHttpOptions;